// // @import url("https://fonts.googleapis.com/css?family=Roboto");

* {
    font-family: 'Roboto Mono';
}

/* You can add global styles to this file, and also import other style files */

//.fa
//    font: normal normal normal 14px/1 FontAwesome!important

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(fonts/MaterialIcons-Regular.eot);
    src: local("Material Icons");
    src: local("MaterialIcons-Regular");
    src: url(fonts/MaterialIcons-Regular.woff2) format("woff2");
    src: url(fonts/MaterialIcons-Regular.woff) format("woff");
    src: url(fonts/MaterialIcons-Regular.ttf) format("truetype");
}

// @font-face {
//     font-family: 'Kyma';
//     src: url(fonts/Kyma.ttf) format("truetype");
// }

// @font-face {
//     font-family: 'SciFly';
//     src: url(fonts/SciFly-Sans.ttf.eot);
//     src: url(fonts/SciFly-Sans.ttf.woff) format("woff");
// }

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}
