/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/** Colors variables */
/** sb-button */
/** font sizes */
/** margin */
/** padding */
/** Share menu variables */
@import url(~ng-pick-datetime/assets/style/picker.min.css);
@import url(~quill/dist/quill.snow.css);
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: center; }

.sb-group {
  flex-wrap: wrap; }

.sb-button {
  margin: 0.3125em; }

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent; }
  .sb-wrapper .sb-icon,
  .sb-wrapper .sb-text,
  .sb-wrapper .sb-count,
  .sb-wrapper .sb-template {
    display: flex;
    align-items: center;
    justify-content: center; }
  .sb-wrapper .sb-inner {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%; }
  .sb-wrapper .sb-content {
    display: flex;
    height: 100%;
    width: 100%; }
  .sb-wrapper .sb-text {
    padding: 0 0.7em; }
  .sb-wrapper .sb-text,
  .sb-wrapper .sb-template {
    flex: 1;
    height: 100%;
    white-space: nowrap; }
  .sb-wrapper .sb-icon {
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 1.2em;
    min-width: 2em; }
  .sb-wrapper .sb-count {
    font-size: 0.9em;
    padding: 0 0.7em; }

/** WITH BUTTON TEXT */
.sb-show-text .sb-icon,
.sb-show-template .sb-icon {
  width: 2em; }

/** WITH CUSTOM TEMPLATE */
.sb-show-template .sb-template {
  padding: 0 0.7em; }

.sb-show-template .sb-template,
.sb-show-template .sb-count {
  white-space: nowrap;
  flex: 1; }

.sb-show-template.sb-show-icon .sb-template {
  padding-left: 0 1em; }

/** WITH COUNT */
.sb-show-count {
  min-width: 5.333em; }

/** Hardcoded Fixes */
/** enlarge whatsapp icon */
.sb-whatsapp .fa.fa-whatsapp {
  font-size: 1.1em; }

/** centralize telegram icon*/
.sb-telegram .fa.fa-send {
  margin-left: -2px; }

.sb-more .fa.fa-ellipsis-h,
.sb-more .fa.fa-minus {
  margin-top: 2px; }

.ae2-support-verifier {
  position: absolute;
  background: #808080;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.ae2-support-verifier--block-scroll {
  overflow: hidden; }

.ae2-support-verifier-desktop-image {
  background: url(/assets/core/images/support-verifier/desktop.jpg);
  background-size: cover;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 652px; }

.ae2-support-verifier-mobile-image {
  background: url(/assets/core/images/support-verifier/mobile.jpg);
  background-size: cover;
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 532px; }

/** Colors variables */
/** sb-button */
/** font sizes */
/** margin */
/** padding */
/** Share menu variables */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: center; }

.sb-group {
  flex-wrap: wrap; }

.sb-button {
  margin: 0.3125em; }

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent; }
  .sb-wrapper .sb-icon,
  .sb-wrapper .sb-text,
  .sb-wrapper .sb-count,
  .sb-wrapper .sb-template {
    display: flex;
    align-items: center;
    justify-content: center; }
  .sb-wrapper .sb-inner {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%; }
  .sb-wrapper .sb-content {
    display: flex;
    height: 100%;
    width: 100%; }
  .sb-wrapper .sb-text {
    padding: 0 0.7em; }
  .sb-wrapper .sb-text,
  .sb-wrapper .sb-template {
    flex: 1;
    height: 100%;
    white-space: nowrap; }
  .sb-wrapper .sb-icon {
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 1.2em;
    min-width: 2em; }
  .sb-wrapper .sb-count {
    font-size: 0.9em;
    padding: 0 0.7em; }

/** WITH BUTTON TEXT */
.sb-show-text .sb-icon,
.sb-show-template .sb-icon {
  width: 2em; }

/** WITH CUSTOM TEMPLATE */
.sb-show-template .sb-template {
  padding: 0 0.7em; }

.sb-show-template .sb-template,
.sb-show-template .sb-count {
  white-space: nowrap;
  flex: 1; }

.sb-show-template.sb-show-icon .sb-template {
  padding-left: 0 1em; }

/** WITH COUNT */
.sb-show-count {
  min-width: 5.333em; }

/** Hardcoded Fixes */
/** enlarge whatsapp icon */
.sb-whatsapp .fa.fa-whatsapp {
  font-size: 1.1em; }

/** centralize telegram icon*/
.sb-telegram .fa.fa-send {
  margin-left: -2px; }

.sb-more .fa.fa-ellipsis-h,
.sb-more .fa.fa-minus {
  margin-top: 2px; }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: SciFly, sans-serif;; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px SciFly, sans-serif;; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px SciFly, sans-serif;; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px SciFly, sans-serif;;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px SciFly, sans-serif;;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: SciFly, sans-serif;; }

.mat-card {
  font-family: SciFly, sans-serif;; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: SciFly, sans-serif;; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: SciFly, sans-serif;; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: SciFly, sans-serif;; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px SciFly, sans-serif;; }

.mat-expansion-panel-header {
  font-family: SciFly, sans-serif;;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: SciFly, sans-serif;; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: SciFly, sans-serif;;
  font-size: 12px; }

.mat-radio-button {
  font-family: SciFly, sans-serif;; }

.mat-select {
  font-family: SciFly, sans-serif;; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-slider-thumb-label-text {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: SciFly, sans-serif;; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: SciFly, sans-serif;; }

.mat-tab-label, .mat-tab-link {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0; }

.mat-tooltip {
  font-family: SciFly, sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: SciFly, sans-serif;; }

.mat-list-option {
  font-family: SciFly, sans-serif;; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: SciFly, sans-serif;;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-simple-snackbar {
  font-family: SciFly, sans-serif;;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: SciFly, sans-serif;; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple {
      display: none; } }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {}@keyframes cdk-text-field-autofill-end {}.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif; }

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.33336333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00103px);
  -ms-transform: translateY(-1.28122em) scale(0.75);
  width: 133.33336333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00104px);
  -ms-transform: translateY(-1.28121em) scale(0.75);
  width: 133.33337333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00105px);
  -ms-transform: translateY(-1.2812em) scale(0.75);
  width: 133.33338333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.33336333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.33336333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px; }

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif; }

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin: 0; }

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif; }

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple {
      display: none; } }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {}@keyframes cdk-text-field-autofill-end {}.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2e2e2e; }

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2e2e2e; }

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336; }

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .mat-pseudo-checkbox::after {
    color: #fafafa; }

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #2e2e2e; }

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2e2e2e; }

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336; }

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.mat-badge-content {
  color: white;
  background: #2e2e2e; }

.mat-badge-accent .mat-badge-content {
  background: #2e2e2e;
  color: white; }

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336; }

.mat-badge {
  position: relative; }

.mat-badge-hidden .mat-badge-content {
  display: none; }

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mat-badge-content.mat-badge-active {
  transform: none; }

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-small .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-medium .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }
  @media screen and (-ms-high-contrast: active) {
    .mat-badge-large .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.mat-bottom-sheet-container {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #2e2e2e; }
  .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #2e2e2e; }
  .mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #f44336; }
  .mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: rgba(46, 46, 46, 0.12); }
  .mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: rgba(46, 46, 46, 0.12); }
  .mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: rgba(244, 67, 54, 0.12); }
  .mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .mat-button.mat-primary .mat-ripple-element, .mat-icon-button.mat-primary .mat-ripple-element, .mat-stroked-button.mat-primary .mat-ripple-element {
    background-color: rgba(46, 46, 46, 0.1); }
  .mat-button.mat-accent .mat-ripple-element, .mat-icon-button.mat-accent .mat-ripple-element, .mat-stroked-button.mat-accent .mat-ripple-element {
    background-color: rgba(46, 46, 46, 0.1); }
  .mat-button.mat-warn .mat-ripple-element, .mat-icon-button.mat-warn .mat-ripple-element, .mat-stroked-button.mat-warn .mat-ripple-element {
    background-color: rgba(244, 67, 54, 0.1); }

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: white; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    color: white; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    color: white; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: #2e2e2e; }
  .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
    background-color: #2e2e2e; }
  .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
    background-color: #f44336; }
  .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.mat-icon-button.mat-primary .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.2); }

.mat-icon-button.mat-accent .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.2); }

.mat-icon-button.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.2); }

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }

.mat-button-toggle-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.26); }
  .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-checkbox-checkmark {
  fill: #fafafa; }

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media screen and (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.mat-checkbox-mixedmark {
  background-color: #fafafa; }

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #2e2e2e; }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2e2e2e; }

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336; }

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0; }

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.mat-checkbox-disabled .mat-checkbox-label {
  color: #b0b0b0; }

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5; } }

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: none; } }

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.26); }

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.26); }

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.26); }

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .mat-chip.mat-standard-chip .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #2e2e2e;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #2e2e2e;
  color: white; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove:hover {
    opacity: 0.54; }

.mat-table {
  background: white; }

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit; }

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.mat-calendar-body-selected {
  background-color: #2e2e2e;
  color: white; }

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(46, 46, 46, 0.4); }

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-content {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #2e2e2e;
    color: white; }
  .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(46, 46, 46, 0.4); }
  .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #f44336;
    color: white; }
  .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, 0.4); }
  .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.mat-datepicker-toggle-active {
  color: #2e2e2e; }
  .mat-datepicker-toggle-active.mat-accent {
    color: #2e2e2e; }
  .mat-datepicker-toggle-active.mat-warn {
    color: #f44336; }

.mat-dialog-container {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #2e2e2e; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #2e2e2e; }
  .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #f44336; }

.mat-focused .mat-form-field-required-marker {
  color: #2e2e2e; }

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #2e2e2e; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #2e2e2e; }
  .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336; }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336; }

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336; }

.mat-error {
  color: #f44336; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #2e2e2e; }

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #2e2e2e; }

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336; }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.mat-icon.mat-primary {
  color: #2e2e2e; }

.mat-icon.mat-accent {
  color: #2e2e2e; }

.mat-icon.mat-warn {
  color: #f44336; }

.mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.38); }

.mat-input-element {
  caret-color: #2e2e2e; }
  .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.mat-accent .mat-input-element {
  caret-color: #2e2e2e; }

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.mat-list-item-disabled {
  background-color: #eeeeee; }

.mat-list-option:hover, .mat-list-option.mat-list-item-focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item.mat-list-item-focus {
  background: rgba(0, 0, 0, 0.04); }

.mat-menu-panel {
  background: white; }

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.mat-menu-item .mat-icon:not([color]),
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.mat-paginator {
  background: white; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-progress-bar-background {
  fill: silver; }

.mat-progress-bar-buffer {
  background-color: silver; }

.mat-progress-bar-fill::after {
  background-color: #2e2e2e; }

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: silver; }

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: silver; }

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #2e2e2e; }

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2; }

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336; }

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2e2e2e; }

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #2e2e2e; }

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336; }

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-radio-disabled .mat-radio-ripple .mat-ripple-element, .mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2e2e2e; }

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #2e2e2e; }

.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.26); }

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2e2e2e; }

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #2e2e2e; }

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.26); }

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336; }

.mat-radio-button.mat-warn .mat-radio-inner-circle {
  background-color: #f44336; }

.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.26); }

.mat-select-content, .mat-select-panel-done-animating {
  background: white; }

.mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12); }

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #2e2e2e; }

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #2e2e2e; }

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336; }

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .mat-drawer.mat-drawer-push {
    background-color: white; }

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #2e2e2e; }

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(46, 46, 46, 0.5); }

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.mat-slide-toggle .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.12); }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #2e2e2e; }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(46, 46, 46, 0.5); }

.mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.mat-slide-toggle.mat-primary .mat-ripple-element {
  background-color: rgba(46, 46, 46, 0.12); }

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f44336; }

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.5); }

.mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.mat-slide-toggle.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.12); }

.mat-disabled .mat-slide-toggle-thumb {
  background-color: #bdbdbd; }

.mat-disabled .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.1); }

.mat-slide-toggle-thumb {
  background-color: #fafafa; }

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #2e2e2e; }

.mat-primary .mat-slider-thumb-label-text {
  color: white; }

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #2e2e2e; }

.mat-accent .mat-slider-thumb-label-text {
  color: white; }

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336; }

.mat-warn .mat-slider-thumb-label-text {
  color: white; }

.mat-slider-focus-ring {
  background-color: rgba(46, 46, 46, 0.2); }

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.38); }

.mat-step-header .mat-step-icon {
  background-color: #2e2e2e;
  color: white; }

.mat-step-header .mat-step-icon-not-touched {
  background-color: rgba(0, 0, 0, 0.38);
  color: white; }

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white; }

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.mat-sort-header-arrow {
  color: #757575; }

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.mat-tab-group[class*='mat-background-'] .mat-tab-header,
.mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.mat-tab-group.mat-primary .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled) {
  background-color: rgba(192, 192, 192, 0.3); }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #2e2e2e; }

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-accent .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled) {
  background-color: rgba(192, 192, 192, 0.3); }

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #2e2e2e; }

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-warn .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336; }

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled) {
  background-color: rgba(192, 192, 192, 0.3); }

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #2e2e2e; }

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled) {
  background-color: rgba(192, 192, 192, 0.3); }

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: #2e2e2e; }

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-focused:not(.cdk-mouse-focused):not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3); }

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #f44336; }

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .mat-toolbar.mat-primary {
    background: #2e2e2e;
    color: white; }
  .mat-toolbar.mat-accent {
    background: #2e2e2e;
    color: white; }
  .mat-toolbar.mat-warn {
    background: #f44336;
    color: white; }
  .mat-toolbar .mat-form-field-underline,
  .mat-toolbar .mat-form-field-ripple,
  .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .mat-toolbar .mat-form-field-label,
  .mat-toolbar .mat-focused .mat-form-field-label,
  .mat-toolbar .mat-select-value,
  .mat-toolbar .mat-select-arrow,
  .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.mat-tree {
  background: white; }

.mat-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.mat-snack-bar-container {
  background: #323232;
  color: white; }

.mat-simple-snackbar-action {
  color: #2e2e2e; }

.ae2-pkg-card {
  display: block;
  min-height: 370px;
  width: 250px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  position: relative; }

.ae2-pkg-card_header {
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ae2-pkg-card-header-background {
  background: #2e2e2e; }

.ae2-pkg-header_title {
  font-size: 24px;
  color: #fafafa;
  margin: 0px; }

.ae2-pkg-header_tagline {
  font-size: 20px;
  color: #fafafa;
  margin: 0px; }

.ae2-pkg-card_content {
  padding: 20px;
  min-height: 170px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ae2-pkg-content_info {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column; }

.ae2-pkg-card_header_discount {
  position: absolute;
  top: -2px;
  left: 0px;
  transform: rotate(-271deg);
  width: 0;
  height: 0;
  border-left: 2px solid transparent;
  border-right: 54px solid transparent;
  border-bottom: 57px solid #f44336; }
  .ae2-pkg-card_header_discount div {
    position: relative; }
    .ae2-pkg-card_header_discount div p {
      margin: 0; }

.ae2-pkg-card_header_discount_percent {
  position: absolute;
  top: 32px;
  transform: rotate(228deg);
  left: -1px; }

.ae2-pkg-card_header_discount_text {
  width: 85px;
  position: absolute;
  transform: rotate(227deg);
  left: -20px;
  top: 26px;
  font-size: 12px; }

.ae2-pkg-content_show_total_amount {
  height: 50px; }

.ae2-pkg-content_title {
  background: #2e2e2e;
  color: #000;
  padding: 4px 10px;
  font-size: 15px; }

.ae2-pkg-content_credits, .ae2-pkg-content_price {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  padding-top: 3px; }
  .ae2-pkg-content_credits .ae2-pkg-content_credits-free, .ae2-pkg-content_price .ae2-pkg-content_credits-free {
    color: red; }

.ae2-pkg-content_saiba_mais {
  position: absolute;
  background: #2e2e2e;
  right: -34px;
  transform: rotate(90deg);
  top: 284px;
  padding: 5px;
  z-index: 100;
  font-size: 15px; }
  .ae2-pkg-content_saiba_mais a {
    text-decoration: none;
    color: #000; }
    .ae2-pkg-content_saiba_mais a:focus {
      outline: none; }

.ae2-pkg-content_price {
  font-size: 27px; }

.ae2-pkg-container_type-sale {
  height: 30px; }

.ae2-pkg-content_validity {
  color: #000;
  font-size: 14px; }
  .ae2-pkg-content_validity .ae2-pkg-content_validity_courtesy {
    color: red; }

.ae2-pkg-content-amount {
  display: block;
  min-height: 40px; }

.ae2-pkg-content_total {
  color: #000;
  font-weight: 700;
  font-size: 13px; }
  .ae2-pkg-content_total:first-child {
    padding-top: 3px; }

.ae2-pkg-content_validity {
  font-size: 13px; }

.ae2-pkg-card_share_info {
  margin-bottom: 10px;
  height: 35px; }

.ae2-pkg-content_active_info {
  padding-top: 7px; }

.ae2-pkg-content_installment {
  color: #000;
  font-size: 13px;
  height: 15px; }

.ae2-pkg-content_total--base {
  text-decoration: line-through; }

.ae2-pkg-content_type-sale {
  color: #000;
  font-size: 12px;
  text-align: center;
  margin: 0; }

.ae2-pkg-card_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px; }

.ae2-pkg-card_action {
  width: 100%; }

.ae2-pkg-card_shareable {
  font-size: 10px;
  color: #000;
  font-weight: bold; }

.ae2-pkg-card_shareable--old {
  text-decoration: line-through; }

.ae2-pkg-card_footer {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ae2-pkg-card_footer_price-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px; }

.ae2-pkg-card_footer_text {
  font-size: 14px; }

.ae2-pkg-card-bg-transparent {
  background: transparent; }

.ae2-pkg-triangle {
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 17px solid #2e2e2e;
  border-bottom: 13px solid transparent; }

.ae2-pkg-triangle_up {
  display: block;
  width: 100%;
  height: 38px;
  background: #2e2e2e;
  position: relative;
  overflow: hidden; }

.ae2-pkg-triangle_up_size1 {
  width: 100%;
  height: 30px;
  background: #fff;
  position: absolute;
  transform: rotate(-16deg);
  top: -30px;
  left: -20px; }

.ae2-pkg-triangle_up_size2 {
  width: 100%;
  height: 30px;
  background: #fff;
  position: absolute;
  transform: rotate(11deg);
  top: -23px;
  right: -20px; }

.ae2-pkg-card-header-bg-transparent {
  background: transparent; }
  .ae2-pkg-card-header-bg-transparent div {
    background: transparent; }

.ae2-bikemap .ae2bikemap-actions {
  width: 100%; }

.ae2-bikemap .ae2bikemap-container {
  max-width: 1170px;
  margin: 0 auto;
  border: 1px solid black; }

.ae2-bikemap .ae2bikemap-container__row {
  display: flex; }

.ae2-bikemap .ae2bikemap-container__row__slot__bike {
  border: 1px solid #2e2e2e;
  position: relative;
  width: 60%;
  height: 0;
  padding: 30% 0%;
  border-radius: 50%;
  text-align: center;
  margin: 20% auto;
  cursor: pointer;
  transition: 0.2s; }
  .ae2-bikemap .ae2bikemap-container__row__slot__bike p {
    margin-top: -9px; }

.ae2-bikemap .ae2bikemap-instructor-container {
  padding: 0;
  position: relative; }

.ae2-bikemap .ae2bikemap-container__row__slot__screen {
  position: relative; }
  .ae2-bikemap .ae2bikemap-container__row__slot__screen .ae2bikemap-screen_text {
    margin: 0;
    position: absolute;
    width: 200%;
    background: black;
    color: white;
    text-align: center;
    margin-top: 17%; }

.ae2-bikemap .ae2bikemap-container__row__slot__instructor {
  border: 1px solid #2e2e2e;
  position: absolute;
  width: 200%;
  height: 0;
  padding: 100% 0;
  border-radius: 50%;
  text-align: center;
  background-size: cover !important;
  background-repeat: no-repeat !important; }

.ae2-bikemap .ae2bikemap-container__row__slot__instructor--no-image {
  background: url(/assets/core/images/bikemap/no-image.png); }

.ae2-bikemap .ae2bikemap-container__row__slot__blank {
  height: 0;
  padding: 50% 0%; }

.ae2-bikemap .ae2bikemap-container__row__slot__door {
  position: relative;
  height: 100%; }

.ae2-bikemap .ae2bikemap-container__row__slot__bike--booked {
  background: #2e2e2e;
  color: white; }

.ae2-bikemap .ae2bikemap-container__row__slot__bike--unavailable {
  background: #D3D3D3;
  border: 1px solid #D3D3D3;
  color: white;
  opacity: 0.5; }

.ae2-bikemap .ae2bikemap-door_image {
  background: url(/assets/core/images/bikemap/door.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%; }

.ae2-bikemap .shiftOddRows {
  left: 50%; }

.ae2-bikemap .cell_shift {
  left: 50%; }

@media screen and (min-width: 1025px) {
  .ae2-bikemap .ae2bikemap-container__row__slot__bike:hover {
    background: #2e2e2e;
    color: white; }
  .ae2-bikemap .ae2bikemap-container__row__slot__bike--unavailable:hover {
    background: #D3D3D3; } }

.ae2-buy-event-ticket-widget {
  display: block;
  width: 280px; }

.ae2bet_card {
  width: 280px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.24);
  margin-bottom: 10px;
  margin-top: 10px; }

.ae2bet_card_column {
  flex-basis: 100%; }

.ae2bet_card_header {
  color: #fff;
  min-height: 50px;
  background: #2e2e2e;
  width: 100%; }

.ae2bet_card_header_icon {
  width: 50px;
  background: #2e2e2e;
  color: #fff; }

.ae2bet_card_header_icon_ticket {
  background: url(/assets/core/images/buy-event-ticket/ticket-icon.png);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 25px;
  height: 13px; }

.ae2bet_card_header_info {
  width: 180px;
  font-size: 16px; }
  .ae2bet_card_header_info * {
    line-height: 20px; }

.ae2bet_card_header_date {
  width: 50px;
  font-size: 16px; }

.ae2bet_card_content {
  padding: 10px 5px; }

.ae2bet_card_content_tagline,
.ae2bet_card_content_show-data-logged-only {
  font-size: 16px;
  color: #76777b; }

.ae2bet_card_content_show-data-logged-only {
  text-align: center; }

.ae2bet_card_content_shop-item {
  border: 1px solid rgba(118, 119, 123, 0.6);
  border-radius: 5px;
  padding: 3px; }

.ae2bet_card_content_shop-item_price {
  color: #000;
  font-size: 16px; }

.ae2bet_card_content_shop-item_card-flags {
  width: 55px;
  height: 30px;
  background: url(https://api.angular-pay.com.br/static/flags.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.ae2fw-header {
  color: #9e9e9e;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center; }

.ae2fw-header_title {
  font-weight: 500;
  font-size: 18px;
  flex: 1 1 100%; }

.ae2fw-header_icon {
  flex: 1 1 25px; }

.ae2fw-content {
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400; }

.ae2tw-box {
  padding: 20px;
  background: #f2f4f5;
  display: flex;
  flex-direction: row; }

.ae2tw-box_photo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 15%; }

.ae2tw-box_photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  justify-content: center; }

.ae2tw-box_photo--no-image {
  background: url(/assets/core/images/testimonial-widget/no-avatar.jpeg); }

.ae2tw-box_photo--video {
  background: url(/assets/core/images/testimonial-widget/play.png);
  background-size: 100%;
  height: 50%;
  width: 50%;
  cursor: pointer; }

.ae2tw-box_card {
  width: 85%;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.24);
  position: relative; }

.ae2tw-box_card--normal:before {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #fff;
  position: absolute;
  left: -20px;
  top: 28%; }

.ae2tw-box_card--inverted:before {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #fff;
  position: absolute;
  right: -20px;
  top: 28%; }

.ae2tw-box_card_title {
  color: #565656;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px; }

.ae2tw-box_card_content {
  color: #565656;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px; }

.ae2tw-box_card_person-name {
  color: #565656;
  font-size: 14px;
  font-weight: 700; }

.ae2tw-box_card_person-work {
  color: #565656;
  font-size: 12px;
  font-weight: 700; }

.ae2tw-dialog_header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #565656;
  margin-bottom: 10px; }

.ae2tw-dialog_header_close {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px; }

.ae2tw-dialog_content_video {
  height: 200px;
  width: 100%; }

@media screen and (min-width: 600px) and (max-width: 959px) {
  .ae2tw-box .ae2tw-box_photo-container .ae2tw-box_photo {
    width: 50px;
    height: 50px; }
  .ae2tw-box .ae2tw-box_card--normal:before {
    top: 35%; }
  .ae2tw-box .ae2tw-box_card--inverted:before {
    top: 35%; } }

@media screen and (max-width: 599px) {
  .ae2tw-box {
    display: flex;
    flex-direction: column !important;
    align-items: center; }
    .ae2tw-box .ae2tw-box_photo-container {
      order: 1;
      width: 100%;
      margin-bottom: 10px; }
      .ae2tw-box .ae2tw-box_photo-container .ae2tw-box_photo {
        width: 50px;
        height: 50px; }
    .ae2tw-box .ae2tw-box_card {
      order: 2; }
    .ae2tw-box .ae2tw-box_card--normal:before,
    .ae2tw-box .ae2tw-box_card--inverted:before {
      border: none; } }

.ae2aw-container {
  min-height: 400px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ae2aw-container__title {
  margin: 10px 0;
  font-size: 60px;
  color: rgba(0, 0, 0, 0.8); }

.ae2aw-container__subtitle {
  margin: 10px 0;
  font-size: 40px;
  color: rgba(0, 0, 0, 0.5); }

.ae2spinner {
  display: flex;
  width: 190px;
  min-height: 45px; }
  .ae2spinner .mat-form-field {
    width: 130px; }
  .ae2spinner input {
    text-align: center; }
  .ae2spinner .disabled {
    color: rgba(0, 0, 0, 0.38); }

.ae2spinner-button {
  width: 25px;
  height: 25px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.24);
  background: #2e2e2e;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff; }
  .ae2spinner-button:focus {
    outline: none; }
  .ae2spinner-button:disabled {
    background: rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.38); }

.ae2Calendar-navigation {
  height: 220px;
  margin: 30px 0;
  padding: 0 50px;
  color: #fff;
  background: url("/assets/core/images/calendar-navigation/background.jpeg");
  display: flex;
  flex-direction: row;
  align-items: center; }
  .ae2Calendar-navigation .ae2Calendar-navigation_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 40px;
    width: 20%; }
    .ae2Calendar-navigation .ae2Calendar-navigation_title i {
      font-size: 40px; }
  .ae2Calendar-navigation .ae2Calendar-navigation_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%; }
    .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container {
      display: flex;
      flex-direction: column; }
      .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_content_title {
        display: flex;
        justify-content: center;
        font-size: 24px; }
      .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions {
        display: flex;
        justify-content: center; }
        .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation__arrow-left,
        .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation__arrow-right {
          cursor: pointer; }
          .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation__arrow-left i,
          .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation__arrow-right i {
            font-size: 60px; }
        .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar_opacity {
          opacity: 0.3; }
        .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation_action_info {
          display: flex;
          align-items: center;
          font-size: 40px; }
  .ae2Calendar-navigation .ae2Calendar-navigation_filters {
    display: flex;
    flex-basis: 20%; }

@media (max-width: 1279px) {
  .ae2Calendar-navigation .ae2Calendar-navigation_content {
    width: 100%; }
  .ae2Calendar-navigation .ae2Calendar-navigation_title,
  .ae2Calendar-navigation .ae2Calendar-navigation_filters {
    display: none; } }

@media (max-width: 599px) {
  .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation__arrow-left i,
  .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation__arrow-right i {
    font-size: 30px; }
  .ae2Calendar-navigation .ae2Calendar-navigation_content .ae2Calendar-navigation_content-container .ae2Calendar-navigation_actions .ae2Calendar-navigation_action_info {
    font-size: 20px; } }

.ae2Calendar-header-mobile {
  margin: 30px 0;
  height: 70px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.24);
  background: #2e2e2e;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content {
    overflow-x: scroll;
    width: 90%;
    display: flex;
    align-items: center;
    height: calc(100% - 10px); }
    .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white; }
      .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item .ae2Calendar-header-mobile_item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%; }
        .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item .ae2Calendar-header-mobile_item-content .ae2Calendar-header-mobile_item-day, .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item .ae2Calendar-header-mobile_item-content .ae2Calendar-header-mobile_item-day--today {
          font-size: 15px; }
        .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item .ae2Calendar-header-mobile_item-content .ae2Calendar-header-mobile_item-day--today {
          text-transform: uppercase; }
        .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item .ae2Calendar-header-mobile_item-content .ae2Calendar-header-mobile_item-date {
          font-size: 18px; }
    .ae2Calendar-header-mobile .ae2Calendar-header-mobile-content .ae2Calendar-header-mobile_item--selected {
      background: black;
      color: white; }

.ae2Calendar-header {
  margin: 30px 0;
  height: 70px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.24);
  padding: 5px;
  background: #2e2e2e; }
  .ae2Calendar-header .ae2Calendar-header_item-container {
    height: 100%; }
    .ae2Calendar-header .ae2Calendar-header_item-container .ae2Calendar-header_item {
      height: 100%;
      width: 100%;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white; }
    .ae2Calendar-header .ae2Calendar-header_item-container .ae2Calendar-header_item--today {
      background: black;
      color: white; }

.ae2-calendar-sticky-header {
  position: fixed;
  top: 0;
  margin: 0;
  z-index: 900; }

.ae2Calendar-filter {
  margin: 30px 0; }

.ae2Calendar-events-container {
  margin-top: 30px; }

.ae2Calendar-event-list {
  padding: 0 2.5px; }
  .ae2Calendar-event-list .ae2Calendar-event-list_item {
    max-width: 200px; }
  .ae2Calendar-event-list .ae2Calendar-event-list_item:first-child,
  .ae2Calendar-event-list .ae2Calendar-event-list_item:not(:last-child) {
    margin-bottom: 5px; }

.ae2-weekly-calendar .ae2event-card {
  width: 100% !important;
  max-width: 250px;
  margin: 0 auto; }
  .ae2-weekly-calendar .ae2event-card .ae2event-data {
    padding: 2px 5px 5px; }
  .ae2-weekly-calendar .ae2event-card .ae2event-data-card {
    padding: 5px; }
    .ae2-weekly-calendar .ae2event-card .ae2event-data-card .ae2event-card-data_info {
      font-size: 13px; }
  .ae2-weekly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content {
    height: 50px;
    text-align: left; }
    .ae2-weekly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content .mat-card-title {
      margin-bottom: -4px; }
    .ae2-weekly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content .ae2event-card-header_tooltip {
      padding-top: 5px; }
    .ae2-weekly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content .ae2event-card-header_content_tagline {
      font-size: 12px;
      margin-left: 16px; }

.ae2Calendar-event-list_item {
  max-width: 100% !important;
  width: 100% !important; }

@media only screen and (min-width: 1280px) and (max-width: 1490px) {
  .event--waiting-list .ae2event-button button,
  .event--waiting-list-available .ae2event-button button {
    font-size: 12px; } }

.ae2Calendar-no-events {
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px; }
  .ae2Calendar-no-events .ae2Calendar-no-events_message {
    font-size: 16px;
    text-align: center;
    max-width: 900px; }

.ae2Calendar-no-events-in-day {
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  margin-bottom: 5px; }
  .ae2Calendar-no-events-in-day .ae2Calendar-no-events_message {
    font-size: 16px;
    text-align: center; }

.ae2-monthly-calendar .ae2Calendar-no-events {
  width: auto; }

.ae2Calendar-messages {
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px; }
  .ae2Calendar-messages .ae2Calendar-messages_message {
    font-size: 16px;
    text-align: center;
    max-width: 900px; }

.ae2Calendar-messages-in-day {
  border: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  margin-bottom: 5px; }
  .ae2Calendar-messages-in-day .ae2Calendar-messages_message {
    font-size: 16px;
    text-align: center; }

.ae2Calendar-month-day {
  height: 200px;
  padding: 5px;
  cursor: pointer; }

.ae2Calendar-month-day--other-month {
  opacity: 0.5; }

.ae2Calendar-month-day:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.ae2Calendar-month-day_header {
  height: 20px; }

.ae2Calendar-month-day_content {
  overflow: auto;
  height: 170px; }

.ae2Calendar-month-day-phone {
  padding: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.24); }

.ae2Calendar-month-day-phone_header {
  padding: 5px;
  margin-bottom: 10px; }

.ae2Calendar-month-days-container {
  margin-top: 30px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.24); }

.ae2Calendar-month-days-container--phone {
  margin: 30px 0; }

.ae2Calendar-month-days-container_week:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.ae2-monthly-calendar .ae2event-card {
  max-width: 200px;
  margin-bottom: 5px; }
  .ae2-monthly-calendar .ae2event-card .ae2event-data {
    padding: 2px 5px 5px; }
  .ae2-monthly-calendar .ae2event-card .ae2event-data-card {
    padding: 5px; }
    .ae2-monthly-calendar .ae2event-card .ae2event-data-card .ae2event-card-data_info {
      font-size: 13px; }
  .ae2-monthly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content {
    height: 50px;
    text-align: left; }
    .ae2-monthly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content .mat-card-title {
      margin-bottom: -4px; }
    .ae2-monthly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content .ae2event-card-header_tooltip {
      padding-top: 5px; }
    .ae2-monthly-calendar .ae2event-card .ae2event-card_header .ae2event-card-header_content .ae2event-card-header_content_tagline {
      font-size: 12px;
      margin-left: 16px; }

.ae2Calendar-month-event-day {
  min-height: 25px;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px; }

.futureEvent {
  background-color: green !important; }

.ae2Calendar-med-layer {
  min-height: 200px;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px; }

.ae2Calendar-med-layer_close {
  color: #fff;
  cursor: pointer; }

.ae2Calendar-text-day-header {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px; }

.ae2Calendar-divider {
  width: 100%;
  height: 1px;
  background: #e0e0e0; }

.ae2Calendar-margin-right-5 {
  margin-right: 5px; }

.ae2Calendar-margin-bottom-5 {
  margin-bottom: 5px; }

.ae2Calendar-mg-bt-10 {
  margin-bottom: 10px; }

.ae2Calendar-margin-top-5 {
  margin-top: 5px; }

.ae2Calendar-mg-tp-10 {
  margin-top: 10px; }

.ae2Calendar-margin-right-10 {
  margin-right: 10px; }

.ae2Calendar-loading-container {
  display: flex;
  align-items: center; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes bounce {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0px);
    opacity: 1; } }

@keyframes scale {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

.ae2-carousel .ae2Carousel-iframe-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative; }

.ae2-carousel .ae2Carousel-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ae2-carousel .ae2Carousel__overlay-content-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }

.ae2-carousel .ae2Carousel__overlay-content-default__title {
  font-size: 80px;
  color: #FFF;
  margin: 10px 0; }

.ae2-carousel .ae2Carousel__overlay-content-default__subtitle {
  font-size: 50px;
  color: #FFF;
  margin: 10px 0; }

.ae2-carousel .ae2Carousel__bullet-container__bullet {
  border: solid 1px #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer; }

.ae2-carousel .ae2Carousel__bullet-container__bullet--selected {
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  background: #fff;
  height: 20px;
  width: 20px; }

.ae2-carousel .ae2Carousel__left-arrow {
  display: none;
  background-image: url(/assets/core/images/carousel/left-arrow.png);
  position: absolute;
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  left: 0;
  z-index: 20;
  transition: all 1s ease-in-out; }

.ae2-carousel .ae2Carousel__right-arrow {
  display: none;
  background-image: url(/assets/core/images/carousel/right-arrow.png);
  position: absolute;
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  right: 0;
  z-index: 20;
  transition: all 1s ease-in-out; }

.ae2-carousel .carousel-container {
  position: relative;
  width: 100%; }
  .ae2-carousel .carousel-container:hover .ae2Carousel__left-arrow,
  .ae2-carousel .carousel-container:hover .ae2Carousel__right-arrow {
    display: block; }

.ae2-carousel .carousel-fade-animation {
  animation: fade 2s; }

.ae2-carousel .carousel-bounce-animation {
  animation: bounce 500ms; }

.ae2-carousel .carousel-scale-animation {
  animation: scale 500ms; }

.ae2-carousel .carousel-container__image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }

.ae2-carousel .image-container__dotted-background {
  background-image: linear-gradient(45deg, #666 25%, transparent 25%), linear-gradient(-45deg, #666 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #666 75%), linear-gradient(-45deg, transparent 75%, #666 75%);
  background-size: 2px 2px;
  background-position: 0 0, 1px 0, 1px -1px, 0px 1px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; }

.ae2-carousel .carousel-container__overlay-content {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%; }

.ae2-carousel .carousel-container__bullet-container {
  position: absolute;
  left: calc(50% - 100px);
  bottom: 0;
  z-index: 20;
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center; }

.ae2-carousel .carousel-animation {
  display: block; }

@media screen and (max-width: 959px) {
  .ae2-carousel .ae2Carousel__overlay-content-default__title {
    font-size: 40px; }
  .ae2-carousel .ae2Carousel__overlay-content-default__subtitle {
    font-size: 25px; }
  .ae2-carousel .carousel-container__bullet-container {
    bottom: -10px; }
  .ae2-carousel .ae2Carousel__bullet-container__bullet,
  .ae2-carousel .ae2Carousel__bullet-container__bullet--selected {
    height: 10px;
    width: 10px; } }

@media screen and (max-width: 599px) {
  .ae2-carousel .ae2Carousel__overlay-content-default__title {
    font-size: 20px; }
  .ae2-carousel .ae2Carousel__overlay-content-default__subtitle {
    font-size: 12.5px; }
  .ae2-carousel .carousel-container__bullet-container {
    bottom: -10px; }
  .ae2-carousel .ae2Carousel__bullet-container__bullet,
  .ae2-carousel .ae2Carousel__bullet-container__bullet--selected {
    height: 10px;
    width: 10px; } }

.ae2-dialog-container .mat-dialog-container {
  max-width: 100%; }

.ae2-dialog-header .ae2-dialog-header-container {
  margin: -24px;
  margin-bottom: 10px;
  padding: 18px;
  background: #2e2e2e; }
  .ae2-dialog-header .ae2-dialog-header-container h1 {
    margin: 0; }
  .ae2-dialog-header .ae2-dialog-header-container mat-icon {
    margin-top: 4px;
    cursor: pointer; }

.ae2-sign-up .ae2-sign-up-container {
  max-width: 700px;
  width: 100%;
  margin: 0 auto; }

.ae2-sign-up .ae2-sign-up-actions {
  padding-top: 10px; }

.ae2-sign-up .ae2-sign-up_progress-bar {
  margin-bottom: 15px; }

.ae2-sign-up .ae2-sign-up_header {
  margin-bottom: 10px; }

.ae2-sign-up .ae2-sign-up_text-step {
  margin: 5px 0 5px 0; }
  .ae2-sign-up .ae2-sign-up_text-step h2 {
    margin: 0; }
  .ae2-sign-up .ae2-sign-up_text-step p {
    margin: 5px; }

.ae2-sign-up .ae2-sign-up-input-100 {
  max-width: 599px;
  width: 100%; }

.ae2-sign-up .ae2-sign-up-send-password {
  padding-top: 15px; }
  @media screen and (max-width: 599px) {
    .ae2-sign-up .ae2-sign-up-send-password {
      padding: 5px; } }

.ae2event-info-dialog_header {
  margin: -24px;
  margin-bottom: 10px;
  padding: 18px;
  background: #2e2e2e; }
  .ae2event-info-dialog_header h1 {
    margin: 0; }

.ae2event-info-dialog_header_close {
  margin-top: 4px;
  cursor: pointer; }

mat-card.ae2event-card {
  width: 242px;
  padding: 0px; }
  mat-card.ae2event-card ::ng-deep ae2-event-status-icon ::ng-deep .material-icons {
    font-size: 20px; }

.ae2event-card_header {
  position: relative;
  background: #e2e2e2;
  display: block !important;
  text-align: center;
  padding: 1px 0px;
  margin-bottom: 0px; }

.ae2event-card_header_imgnew {
  position: absolute; }

.ae2event-card-header_menu {
  position: absolute;
  right: 0;
  top: 6px; }
  .ae2event-card-header_menu ae2-event-menu .mat-icon-button {
    width: 25px;
    height: 43px; }

.ae2event-card-header_content mat-card-title {
  font-size: 20px !important;
  margin: 3px 0px; }

.ae2event-card-header_date {
  font-size: 14px; }

.ae2event-data {
  padding: 2px 10px 10px; }

.ae2event-data-card {
  box-shadow: 1px 3px 1px -2px rgba(0, 0, 0, 0), 0px 2px 2px 0 rgba(0, 0, 0, 0.18), 0px 1px 5px 0 rgba(0, 0, 0, 0.49) !important;
  display: flex;
  flex-direction: row; }

.mat-card .ae2event-data-card {
  padding: 10px; }

.ae2event-card-data_info {
  font-size: 14px;
  align-items: center;
  height: 30px; }
  .ae2event-card-data_info p {
    margin: 0; }

.ae2event-button {
  margin-top: 8px; }
  .ae2event-button button {
    background-color: #e2e2e2; }

.ae2event-data-icons {
  height: 32px; }
  .ae2event-data-icons .fa-file-text {
    font-size: 17px !important;
    margin-top: -1px; }
  .ae2event-data-icons .people {
    font-size: 25px !important;
    margin-top: 2px; }
  .ae2event-data-icons .insert_emoticon {
    font-size: 22px !important; }
  .ae2event-data-icons .check_circle {
    color: green;
    font-size: 22px !important; }
  .ae2event-data-icons .ae2event-ticket-icon {
    margin-right: -5px; }
  .ae2event-data-icons ae2-event-open-info-dialog ::ng-deep .info {
    font-size: 23px;
    margin: 2px 3px; }

.ae2event-card-data_info_instructor,
.ae2event-card-data_info_activity,
.ae2event-card-header_tooltip {
  white-space: nowrap;
  overflow: hidden; }

.ae2event-card-header_tooltip {
  position: relative;
  margin: 0 16px;
  font-size: 16px; }

.ae2event-card-header-marginLeft {
  margin-left: 45px; }

.ae2event-card-header_tooltip .ae2event-card-header_tooltiptext {
  opacity: 0;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  position: absolute;
  width: 100%;
  font-size: 20px;
  z-index: 1;
  bottom: -150%;
  left: 0;
  transition: 0.7s;
  transition-delay: 0.6s; }

.ae2event-card-header_tooltip .ae2event-card-header_tooltiptext::after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.ae2event-card-header_tooltip:hover .ae2event-card-header_tooltiptext {
  opacity: 1; }

.ae2event-card-data-activity-icon {
  background-image: url(/assets/core/images/event-widget/heart-icon.svg);
  padding: 10px;
  margin: 2px; }

.ae2event-subscriber-counter {
  height: 4px;
  width: 100%;
  border: 1px solid #2e2e2e;
  margin: 17px 0px;
  position: relative; }

.ae2event-counter-background {
  background: #2e2e2e;
  height: 100%; }

.ae2event-counter {
  position: absolute;
  background: #fff;
  border: 1px solid #2e2e2e;
  color: #2e2e2e;
  padding: 4px;
  border-radius: 15px;
  font-size: 13px;
  top: 3px;
  left: 50%;
  transform: translate(-50%, -50%); }

.ae2event-warnColor {
  background: #f44336; }

.ae2event-event-label {
  height: 60px;
  width: 60px;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  z-index: 100;
  display: block; }

.ae2event-new-label {
  background-image: url(/assets/core/images/event-widget/event-new-label.png);
  height: 60px;
  width: 60px;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  z-index: 100;
  display: block; }

.ae2event-full-label {
  background-image: url(/assets/core/images/event-widget/event-full-label.png);
  height: 60px;
  width: 60px;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  z-index: 100;
  display: block; }

.ae2event-flame-icon {
  background-image: url(/assets/core/images/event-widget/flame-icon.png);
  width: 16px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat; }

.ae2event-coin-icon {
  background-image: url(/assets/core/images/event-widget/coin-icon.png);
  width: 21px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat; }

.ae2event-ticket-icon {
  background-image: url(/assets/core/images/event-widget/ticket-icon.png);
  width: 21px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat; }

.ae2event-green-icon {
  color: red; }

.ae2event-mg-bt-20 {
  margin-bottom: 20px; }

.ae2event-full-width.mat-form-field {
  width: 100%; }

.ae2event-full-width.ae2-chips .mat-form-field {
  width: 100%; }

.ae2-feedback {
  display: flex;
  width: 100%; }
  .ae2-feedback .ae2-feedback-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100px;
    flex: 0 0 15%; }
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--success {
      background: url(/assets/core/images/feedback/success-icon.png);
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover; }
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--error {
      background: url(/assets/core/images/feedback/error-icon.png);
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover; }
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--info {
      background: url(/assets/core/images/feedback/info-icon.png);
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover; }
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--warn {
      background: url(/assets/core/images/feedback/warn-icon.png);
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover; }
  .ae2-feedback .ae2-feedback-message {
    display: flex;
    align-items: center;
    flex: 1 0 0; }
  .ae2-feedback .ae2-feedback-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    max-width: 100px;
    flex: 0 0 10%; }

@media (max-width: 600px) {
  .ae2-feedback .ae2-feedback-action {
    min-width: 40px; }
  .ae2-feedback .ae2-feedback-icon {
    min-width: 40px; }
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--success,
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--error,
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--info,
    .ae2-feedback .ae2-feedback-icon .ae2-feedback-icon--warn {
      width: 30px;
      height: 30px; } }

.ae2-feedback-error .ae2-feedback-icon,
.ae2-feedback-error .ae2-feedback-message,
.ae2-feedback-error .ae2-feedback-message a,
.ae2-feedback-error .ae2-feedback-action {
  color: #ea5048; }

.ae2-feedback-warn .ae2-feedback-icon,
.ae2-feedback-warn .ae2-feedback-message,
.ae2-feedback-warn .ae2-feedback-message a,
.ae2-feedback-warn .ae2-feedback-action {
  color: #facd3a; }

.ae2-feedback-info .ae2-feedback-icon,
.ae2-feedback-info .ae2-feedback-message,
.ae2-feedback-info .ae2-feedback-message a,
.ae2-feedback-info .ae2-feedback-action {
  color: #31708f; }

.ae2-feedback-success .ae2-feedback-icon,
.ae2-feedback-success .ae2-feedback-message,
.ae2-feedback-success .ae2-feedback-message a,
.ae2-feedback-success .ae2-feedback-action {
  color: #70c104; }

.ae2-footer {
  background-color: #2e2e2e;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;
  margin-top: 30px; }

.ae2-footer_top {
  box-sizing: border-box;
  padding: 60px 0 20px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  display: flex; }
  @media (max-width: 1000px) {
    .ae2-footer_top {
      flex-direction: column; } }
  @media (min-width: 1001px) {
    .ae2-footer_top {
      flex-direction: row; } }

.ae2-footer_bottom {
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  color: silver;
  justify-content: space-between;
  align-items: center;
  display: flex; }
  @media (max-width: 1000px) {
    .ae2-footer_bottom {
      height: 60px;
      flex-direction: column; } }
  @media (min-width: 1001px) {
    .ae2-footer_bottom {
      height: 30px;
      flex-direction: row; } }
  .ae2-footer_bottom div {
    height: 30px;
    line-height: 30px; }
  .ae2-footer_bottom .ae2-footer_bottom_powered {
    color: silver; }
    @media (max-width: 1000px) {
      .ae2-footer_bottom .ae2-footer_bottom_powered {
        margin: 10px 0; } }

.ae2-footer-session {
  padding: 20px 0;
  flex: 1 1 25%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (max-width: 1000px) {
    .ae2-footer-session {
      flex: 1 1 auto; } }
  @media (min-width: 1001px) {
    .ae2-footer-session {
      flex: 1 1 25%; } }

@media (min-width: 1000px) {
  .ae2-footer-session {
    border-left: 1px solid rgba(255, 255, 255, 0.2); }
  .ae2-footer-session:first-child {
    border-left: 0; } }

.ae2-footer-logo {
  align-items: center;
  display: flex; }
  @media (max-width: 1000px) {
    .ae2-footer-logo {
      justify-content: center;
      flex-direction: column; } }
  @media (min-width: 1001px) {
    .ae2-footer-logo {
      justify-content: space-between;
      flex-direction: row; } }
  .ae2-footer-logo img {
    height: auto; }
    @media (max-width: 1000px) {
      .ae2-footer-logo img {
        flex: 1 1 50%;
        margin-right: 0; } }
    @media (min-width: 1001px) {
      .ae2-footer-logo img {
        flex: 1 1 20%;
        margin-right: 10%; } }
  .ae2-footer-logo .footer_logo_description {
    color: silver;
    font-size: 13px; }
    @media (max-width: 1000px) {
      .ae2-footer-logo .footer_logo_description {
        flex: 1 1 90%;
        text-align: center;
        margin-right: 0; } }
    @media (min-width: 1001px) {
      .ae2-footer-logo .footer_logo_description {
        flex: 1 1 60%;
        text-align: justify;
        margin-right: 10%; } }

.ae2-footer-links {
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex: 1 1 auto; }
  @media (min-width: 1000px) {
    .ae2-footer-links {
      align-items: flex-start; } }
  @media (max-width: 1000px) {
    .ae2-footer-links {
      align-items: center; } }
  .ae2-footer-links .footer_link_item {
    cursor: pointer;
    color: silver;
    font-size: 16px; }
    @media (min-width: 1000px) {
      .ae2-footer-links .footer_link_item {
        margin: 0 0 3px 10%; } }
    @media (max-width: 1000px) {
      .ae2-footer-links .footer_link_item {
        margin: 0 0 3px 0; } }

.ae2-footer-newsletter {
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  display: flex;
  flex: 1 1 auto; }
  .ae2-footer-newsletter .footer-newsletter_label {
    color: silver;
    font-size: 13px;
    margin: 0 0 5px 10%; }
  .ae2-footer-newsletter .footer-newsletter_form {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: stretch;
    width: 80%;
    margin-left: 10%; }
  .ae2-footer-newsletter input {
    flex: 1 1 auto;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
    border: 0;
    color: silver;
    padding-left: 5px; }
  .ae2-footer-newsletter button {
    flex: 1 1 20px;
    box-sizing: border-box;
    text-align: right;
    padding-right: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    border: 0;
    color: silver; }
    .ae2-footer-newsletter button .mat-icon {
      line-height: 30px; }
  .ae2-footer-newsletter button[disabled] .mat-icon {
    opacity: 0.2; }

.ae2-footer-payments {
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 10px 10% 0;
  flex: 1 1 auto; }
  .ae2-footer-payments .footer-payments_label {
    color: silver;
    font-size: 13px;
    margin-right: 10px; }
  .ae2-footer-payments .footer-payments_cards {
    height: 18px; }

.ae2-footer-address {
  flex-direction: column;
  justify-content: center;
  display: flex;
  flex: 1 1 auto;
  margin: 0 10%; }
  .ae2-footer-address .footer-address_row {
    flex-direction: row;
    justify-content: flex-start;
    display: flex;
    color: silver;
    font-size: 13px;
    margin-bottom: 10px; }
    .ae2-footer-address .footer-address_row .footer-address_label {
      flex: 1 1 25%; }
    .ae2-footer-address .footer-address_row .footer-address_value {
      flex: 1 1 75%; }
  .ae2-footer-address .footer-address_row:last-child {
    margin-bottom: 0; }

.ae2-footer-follow {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex: 1 1 auto;
  margin: 10px 10% 0; }
  .ae2-footer-follow .footer-follow_label {
    color: silver;
    font-size: 13px;
    margin-right: 10px;
    flex: 1 1 25%; }
  .ae2-footer-follow .footer-follow_iconset {
    flex: 1 1 75%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex; }
  .ae2-footer-follow .footer-follow_icon {
    margin-right: 13px;
    cursor: pointer;
    color: silver;
    font-size: 13px; }

.ae2-footer-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }

.ae2-unit-selector {
  clear: both;
  float: left; }

.ae2-unit-selector-label {
  cursor: pointer; }

.ae2-unit-selector-change {
  color: #2e2e2e; }

.ae2-unit-selector-pane {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  padding: 10px 30px;
  background: #ffffff; }
  .ae2-unit-selector-pane .ae2-unit-selector-layer .ae2-unit-selector-location {
    padding-top: 10px;
    padding-bottom: 10px; }
  .ae2-unit-selector-pane .ae2-unit-selector-layer .ae2-unit-selector-location-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .ae2-unit-selector-pane .ae2-unit-selector-layer .ae2-unit-selector-location-title {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin-bottom: 5px; }
  .ae2-unit-selector-pane .ae2-unit-selector-layer .ae2-unit-selector-unit {
    font-size: 14px;
    color: #555;
    padding-left: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative; }
  .ae2-unit-selector-pane .ae2-unit-selector-layer .ae2-unit-selector-unit--selected {
    color: #2e2e2e; }
    .ae2-unit-selector-pane .ae2-unit-selector-layer .ae2-unit-selector-unit--selected ::before {
      content: '>';
      position: absolute;
      left: 0px; }

.ae2-embedded-checkout .ae2-embedded-checkout-loading {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); }
  .ae2-embedded-checkout .ae2-embedded-checkout-loading .ae2-embedded-checkout-loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    text-align: center;
    padding: 50px; }
    .ae2-embedded-checkout .ae2-embedded-checkout-loading .ae2-embedded-checkout-loading-content i {
      color: #2e2e2e; }
    .ae2-embedded-checkout .ae2-embedded-checkout-loading .ae2-embedded-checkout-loading-content .message {
      margin-top: 10px;
      color: #fff; }

.ae2-embedded-checkout .ae2-embedded-checkout-pro-rata-action {
  cursor: pointer;
  margin-left: 10px; }

.ae2-embedded-checkout .ae2-embedded-checkout-container {
  display: flex;
  justify-content: center; }

.ae2-embedded-checkout .ae2-embedded-checkout_form-error {
  color: red; }

.ae2-embedded-checkout .ae2-embedded-checkout_divider {
  height: 1px;
  background: #e0e0e0; }

.ae2-embedded-checkout .ae2-embedded-checkout_mg-bt-20 {
  margin-bottom: 20px; }

.ae2-embedded-checkout .ae2-embedded-checkout_mg-tp-20 {
  margin-top: 20px; }

.ae2-embedded-checkout .ae2-embedded-checkout_mg-bt-25 {
  margin-bottom: 25px; }

.ae2-embedded-checkout .ae2-embedded-checkout_mg-bt-10 {
  margin-bottom: 20px; }

.ae2-embedded-checkout .ae2-embedded-checkout_mg-rt {
  margin-right: 25px; }

.ae2-embedded-checkout .ae2-embedded-checkout_instructions {
  color: #565656; }

.ae2-embedded-checkout .ae2-embedded-checkout_box-message {
  padding: 5px;
  border: 1px solid #e0e0e0;
  min-height: 100px;
  color: #565656; }

.ae2-embedded-checkout .ae2-embedded-checkout_item-image {
  width: 100px;
  height: 80px; }

.ae2-embedded-checkout .ae2-embedded-checkout_item-title {
  font-size: 24px;
  color: #565656; }

.ae2-embedded-checkout .ae2-embedded-checkout_item-substitle {
  font-size: 14px;
  color: #565656; }

.ae2-embedded-checkout .ae2-embedded-checkout-item-discount {
  color: #565656; }
  @media screen and (max-width: 600px) {
    .ae2-embedded-checkout .ae2-embedded-checkout-item-discount {
      text-align: center; } }

.ae2-embedded-checkout .ae2-embedded-checkout-item-exclusive-unit {
  color: #31708f;
  font-weight: bold; }
  @media screen and (max-width: 600px) {
    .ae2-embedded-checkout .ae2-embedded-checkout-item-exclusive-unit {
      text-align: center; } }

.ae2-embedded-checkout .ae2-embedded-checkout-discount {
  color: #565656;
  font-size: 14px; }

.ae2-embedded-checkout .ae2-embedded-checkout_cash-card {
  height: 60px;
  width: 100%;
  background: #e0e0e0;
  padding: 0 24px;
  font-size: 18px; }

.ae2-embedded-checkout .ae2-embedded-checkout_cash-card-pro-rata {
  background: #e0e0e0;
  padding: 24px;
  font-size: 18px; }
  .ae2-embedded-checkout .ae2-embedded-checkout_cash-card-pro-rata .info {
    color: #565656;
    font-size: 15px;
    margin-top: 10px; }

.ae2-embedded-checkout .ae2-embedded-checkout-applied-coupon-text {
  color: #565656; }

.ae2-embedded-checkout .flex-auto {
  flex: 1 1 auto !important; }

.ae2-embedded-checkout .force-input-width.mat-form-field {
  width: 100%; }

.ae2-embedded-checkout .ae2-embedded-checkout-payment {
  flex-direction: column;
  display: flex;
  order: 2; }

.ae2-embedded-checkout .ae2-embedded-checkout-itens {
  flex-direction: column;
  display: flex;
  order: 1; }

@media screen and (min-width: 960px) {
  .ae2-embedded-checkout .ae2-embedded-checkout-payment {
    order: 1;
    flex: 50%; }
  .ae2-embedded-checkout .ae2-embedded-checkout-itens {
    order: 2;
    flex: 50%; } }

a {
  cursor: pointer; }

.ae2-header-widget {
  z-index: 1000;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  transition: all .3s ease 0s; }
  .ae2-header-widget ae2-control-messages.ae2-form-error {
    display: block;
    margin-top: -10px;
    width: 100%;
    min-height: 15px; }
    .ae2-header-widget ae2-control-messages.ae2-form-error .ae2-form-error, .ae2-header-widget ae2-control-messages.ae2-form-error .ae2-pending-form {
      color: #f44336;
      font-size: 12px;
      width: 100%;
      text-align: right; }
    .ae2-header-widget ae2-control-messages.ae2-form-error .ae2-pending-form {
      color: grey; }
  .ae2-header-widget ae2-control-messages.ae2-form-error-general {
    display: block;
    width: 100%;
    min-height: 15px; }
    .ae2-header-widget ae2-control-messages.ae2-form-error-general .ae2-form-error-general {
      color: #f44336;
      font-size: 12px;
      width: 100%;
      text-align: right; }
  .ae2-header-widget.transparent:not(.opaque):not(.login-or-signup) .ae2-header-widget-top {
    background-color: rgba(0, 0, 0, 0.2);
    color: #2e2e2e; }
  .ae2-header-widget.transparent:not(.opaque):not(.login-or-signup) .ae2-header-widget-bottom,
  .ae2-header-widget.transparent:not(.opaque):not(.login-or-signup) .ae2-header-submenu-widget {
    background-color: rgba(0, 0, 0, 0);
    color: #2e2e2e; }
  .ae2-header-widget.transparent:not(.opaque):not(.login-or-signup) .ae2-header-social i {
    color: #fafafa; }
  .ae2-header-widget.transparent:not(.opaque):not(.login-or-signup) a:not(.sidenav-link) {
    color: #2e2e2e; }
  .ae2-header-widget.expanded {
    box-shadow: 0px 3px 6px 0px transparent; }
    .ae2-header-widget.expanded .ae2-header-submenu-widget {
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.4); }
  .ae2-header-widget.login-or-signup .ae2-header-widget-login-or-signup-closer {
    bottom: 0; }
  .ae2-header-widget a {
    color: #2e2e2e; }

.ae2-header-widget-login-or-signup {
  height: auto;
  transition: all .3s ease 0s;
  background-color: #c7c7c7;
  z-index: 1000;
  padding: 10px 0; }
  .ae2-header-widget-login-or-signup > * {
    max-width: 520px; }

.ae2-header-widget-login-or-signup-closer {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ae2-header-widget-pre-header-mobile {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #c7c7c7;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden; }

.ae2-header-widget-top {
  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 3px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  transition: all .3s ease 0s; }

.ae2-header-social i {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #333;
  transition: all .3s ease 0s; }

.ae2-header-login {
  font-size: 14px; }
  .ae2-header-login mat-icon {
    font-size: 20px;
    height: 20px;
    line-height: 20px;
    width: 20px; }

.ae2-header-widget-bottom {
  height: 70px;
  padding: 0 5px;
  position: relative;
  transition: all .3s ease 0s; }

.ae2-header-logo {
  background: url("/assets/images/logo.png") no-repeat 0 50%;
  width: 100%;
  height: 48px;
  margin-top: 12px;
  cursor: pointer;
  background-size: contain; }
  @media (max-width: 800px) {
    .ae2-header-logo {
      background-position: 50% 50%; } }

.ae2-header-menu {
  height: 100%; }

.ae2-header-submenu {
  position: relative;
  height: 100%;
  z-index: 1000; }

.ae2-header-menu-item,
.ae2-header-submenu-item {
  text-transform: uppercase;
  font-size: 14px;
  height: 100%;
  background-color: transparent;
  transition: all .3s ease 0s;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  margin: 0;
  min-width: 88px;
  padding: 0 16px;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.ae2-header-menu-item:hover,
.ae2-header-submenu-item:hover {
  background-color: rgba(0, 0, 0, 0.2); }
  .ae2-header-menu-item:hover a,
  .ae2-header-submenu-item:hover a {
    width: 100%;
    height: 100%; }

.ae2-header-item-subitems mat-icon {
  transform: rotate3d(0, 0, 1, 0deg); }

.ae2-header-item-subitems mat-icon.active {
  transform: rotate3d(0, 0, 1, 180deg); }

.ae2-header-submenu-widget {
  width: 100%;
  background-color: #fafafa;
  height: 0;
  border-top: 0px solid transparent;
  box-shadow: 0px 3px 6px 0px transparent;
  transition: all .3s ease 0s;
  overflow: hidden; }
  .ae2-header-submenu-widget.active {
    height: 40px;
    border-top-width: 1px; }
    .ae2-header-submenu-widget.active .ae2-header-submenu-closer {
      bottom: 0;
      z-index: 999; }
  .ae2-header-submenu-widget .ae2-header-submenu-closer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
    z-index: -1; }

.ae2-sign-up-form-wrapper {
  width: 600px; }
  @media (max-width: 800px) {
    .ae2-sign-up-form-wrapper {
      width: 80%;
      margin-left: 10%; } }

.ae2-sign-up-row {
  width: 100%; }

.ae2-sign-up-input-container {
  width: 100%; }

.ae2-sign-up-input {
  width: 100%; }

.ae2-sign-up-select {
  min-width: 100%;
  max-width: 100%;
  width: 100%; }
  .ae2-sign-up-select .mat-select-trigger {
    min-width: auto; }

.ae2-sign-up-error {
  color: #f44336;
  margin-top: -20px;
  font-size: 12px;
  text-align: right; }

@media (max-width: 800px) {
  .ae2-sign-up-top {
    width: 80%; } }

.ae2-sign-up-lastrow {
  height: 36px; }

.ae2-header-mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px; }
  .ae2-header-mobile-menu mat-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 40px;
    height: 40px;
    width: 40px; }

.ae2-header-sidenav-closer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 100%;
  background: #000000;
  opacity: 0;
  transition: opacity .5s ease 0s, right 0s ease .5s;
  z-index: 1000; }
  .ae2-header-sidenav-closer.active {
    right: 0;
    opacity: .6;
    transition: opacity .5s ease 0s, right 0s ease 0s; }

.ae2-header-sidenav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fafafa;
  transition: all .5s ease 0s;
  z-index: 1000;
  color: #2e2e2e;
  padding: 20px 0 0;
  box-sizing: border-box; }
  .ae2-header-sidenav.active {
    right: 20%;
    padding: 20px 10px 0; }
  .ae2-header-sidenav .ae2-header-sidenav-item,
  .ae2-header-sidenav .ae2-header-sidenav-item-myaccount {
    min-width: 300px;
    color: #2e2e2e;
    height: 50px; }
  .ae2-header-sidenav .ae2-header-sidenav-item-myaccount {
    padding-left: 20px; }
  .ae2-header-sidenav .ae2-header-sidenav-item-page a,
  .ae2-header-sidenav .ae2-header-sidenav-item-session a {
    color: #2e2e2e; }

.ae2-form-error {
  color: #f44336; }

.ae2-pending-form {
  color: grey; }

.ae2-iosclick {
  cursor: pointer; }

/**
    Comentado pois causou a issue abaixo 
    https://gitlab.com/angular-ecommerce/frontend/core/dashboard/issues/651
    */
/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: SciFly, sans-serif;; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px SciFly, sans-serif;; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px SciFly, sans-serif;; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px SciFly, sans-serif;;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px SciFly, sans-serif;;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: SciFly, sans-serif;; }

.mat-card {
  font-family: SciFly, sans-serif;; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: SciFly, sans-serif;; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: SciFly, sans-serif;; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: SciFly, sans-serif;; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px SciFly, sans-serif;; }

.mat-expansion-panel-header {
  font-family: SciFly, sans-serif;;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: SciFly, sans-serif;; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: SciFly, sans-serif;;
  font-size: 12px; }

.mat-radio-button {
  font-family: SciFly, sans-serif;; }

.mat-select {
  font-family: SciFly, sans-serif;; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-slider-thumb-label-text {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: SciFly, sans-serif;; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: SciFly, sans-serif;; }

.mat-tab-label, .mat-tab-link {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0; }

.mat-tooltip {
  font-family: SciFly, sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: SciFly, sans-serif;; }

.mat-list-option {
  font-family: SciFly, sans-serif;; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: SciFly, sans-serif;;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-simple-snackbar {
  font-family: SciFly, sans-serif;;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: SciFly, sans-serif;; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple {
      display: none; } }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {}@keyframes cdk-text-field-autofill-end {}.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

.form-error {
  color: #f44336;
  margin-bottom: 5px; }

.form-checking {
  color: var(--accent);
  margin-bottom: 5px; }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: SciFly, sans-serif;; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px SciFly, sans-serif;; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px SciFly, sans-serif;; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px SciFly, sans-serif;;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px SciFly, sans-serif;;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: SciFly, sans-serif;; }

.mat-card {
  font-family: SciFly, sans-serif;; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: SciFly, sans-serif;; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: SciFly, sans-serif;; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: SciFly, sans-serif;; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px SciFly, sans-serif;; }

.mat-expansion-panel-header {
  font-family: SciFly, sans-serif;;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: SciFly, sans-serif;; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: SciFly, sans-serif;;
  font-size: 12px; }

.mat-radio-button {
  font-family: SciFly, sans-serif;; }

.mat-select {
  font-family: SciFly, sans-serif;; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-slider-thumb-label-text {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: SciFly, sans-serif;; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: SciFly, sans-serif;; }

.mat-tab-label, .mat-tab-link {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0; }

.mat-tooltip {
  font-family: SciFly, sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: SciFly, sans-serif;; }

.mat-list-option {
  font-family: SciFly, sans-serif;; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: SciFly, sans-serif;;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-simple-snackbar {
  font-family: SciFly, sans-serif;;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: SciFly, sans-serif;; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple {
      display: none; } }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {}@keyframes cdk-text-field-autofill-end {}.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

ae2-splash-screen-3bounce {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  ae2-splash-screen-3bounce .sk-three-bounce {
    margin: 40px auto;
    width: 80px;
    text-align: center; }
    ae2-splash-screen-3bounce .sk-three-bounce .sk-child {
      width: 20px;
      height: 20px;
      background-color: #2e2e2e;
      border-radius: 100%;
      display: inline-block;
      animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; }
    ae2-splash-screen-3bounce .sk-three-bounce .sk-bounce1 {
      animation-delay: -0.32s; }
    ae2-splash-screen-3bounce .sk-three-bounce .sk-bounce2 {
      animation-delay: -0.16s; }

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

* {
  font-family: 'Roboto Mono'; }

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/MaterialIcons-Regular.eot);
  src: local("Material Icons");
  src: local("MaterialIcons-Regular");
  src: url(fonts/MaterialIcons-Regular.woff2) format("woff2");
  src: url(fonts/MaterialIcons-Regular.woff) format("woff");
  src: url(fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: SciFly, sans-serif;; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px SciFly, sans-serif;; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px SciFly, sans-serif;; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px SciFly, sans-serif;;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px SciFly, sans-serif;;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: SciFly, sans-serif;; }

.mat-card {
  font-family: SciFly, sans-serif;; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: SciFly, sans-serif;; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: SciFly, sans-serif;; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: SciFly, sans-serif;; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px SciFly, sans-serif;; }

.mat-expansion-panel-header {
  font-family: SciFly, sans-serif;;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: SciFly, sans-serif;; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: SciFly, sans-serif;;
  font-size: 12px; }

.mat-radio-button {
  font-family: SciFly, sans-serif;; }

.mat-select {
  font-family: SciFly, sans-serif;; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-slider-thumb-label-text {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: SciFly, sans-serif;; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: SciFly, sans-serif;; }

.mat-tab-label, .mat-tab-link {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0; }

.mat-tooltip {
  font-family: SciFly, sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: SciFly, sans-serif;; }

.mat-list-option {
  font-family: SciFly, sans-serif;; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: SciFly, sans-serif;;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-simple-snackbar {
  font-family: SciFly, sans-serif;;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: SciFly, sans-serif;; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple {
      display: none; } }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {}@keyframes cdk-text-field-autofill-end {}.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }

:root {
  --primary: #2e2e2e;
  --accent: #2e2e2e;
  --warn: #f44336;
  --white: white;
  --black: #141414;
  --grey: #5b5f64;
  --greylight: #f2f4f5; }

html, body {
  margin: 0;
  height: 100%; }

body {
  overflow-x: hidden; }

app-root,
.fullscreen {
  display: block;
  width: 100%;
  height: 100%; }

app-layout {
  display: block;
  width: 100%;
  height: 100%; }

* {
  outline: none; }

.page-container {
  max-width: 1280px;
  padding: 20px; }

a {
  text-decoration: none; }

.cards-custom {
  color: #ffffff;
  font-size: 13px; }

.cards-custom .mat-input-placeholder {
  color: #ffffff !important;
  font-size: 12px; }

.cards-custom .mat-focused {
  color: #ffffff !important; }

.cards-custom .mat-input-underline {
  border-color: #ffffff; }

.cards-custom .mat-input-underline .mat-input-ripple {
  background-color: #ffffff; }

.cards-custom .mat-checkbox-label {
  font-size: 12px; }

.cards-custom .mat-checkbox-background {
  background: #ffffff !important; }

.cards-custom .mat-checkbox-checkmark-path {
  stroke: #03a9f4 !important; }

.cards-customTwo {
  color: #ffffff;
  font-size: 13px;
  width: 100%; }

.cards-customTwo .mat-input-placeholder {
  color: #ffffff !important;
  font-size: 10px; }

.cards-customTwo .mat-focused {
  color: #ffffff !important; }

.cards-customTwo .mat-input-underline {
  border-color: #ffffff; }

.cards-customTwo .mat-input-underline .mat-input-ripple {
  background-color: #ffffff; }

.cards-customTwo .mat-checkbox-label {
  font-size: 12px; }

.cards-customTwo .mat-checkbox-background {
  background: #ffffff !important; }

.cards-customTwo .mat-checkbox-checkmark-path {
  stroke: #03a9f4 !important; }

.cardWritten_card_header .mat-card-header-text {
  align-items: center;
  display: flex; }

.cardWritten_card_header .mat-card-title {
  margin-bottom: 0px; }

.pagesCustom {
  background: white;
  margin-bottom: 0px !important; }
  .pagesCustom ::ng-deep .navDefault_container_button {
    color: #141414; }

.cdk-global-scrollblock {
  top: 0px !important; }

.accent-color {
  color: #2e2e2e; }

.warn-color {
  color: #f44336; }

.mat-menu-content {
  background: var(--primary);
  color: var(--white); }

.ae2-dialog-header-container h1 {
  color: var(--white); }

.mat-dialog-title {
  color: var(--white); }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: SciFly, sans-serif;; }

.mat-badge-small .mat-badge-content {
  font-size: 6px; }

.mat-badge-large .mat-badge-content {
  font-size: 24px; }

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px SciFly, sans-serif;;
  margin: 0 0 16px; }

.mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px SciFly, sans-serif;;
  margin: 0 0 12px; }

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px SciFly, sans-serif;; }
  .mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px; }

.mat-small, .mat-caption {
  font: 400 12px/20px SciFly, sans-serif;; }

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px SciFly, sans-serif;;
  margin: 0 0 56px;
  letter-spacing: -0.05em; }

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.02em; }

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px SciFly, sans-serif;;
  margin: 0 0 64px;
  letter-spacing: -0.005em; }

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px SciFly, sans-serif;;
  margin: 0 0 64px; }

.mat-bottom-sheet-container {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-button-toggle {
  font-family: SciFly, sans-serif;; }

.mat-card {
  font-family: SciFly, sans-serif;; }

.mat-card-title {
  font-size: 24px;
  font-weight: 400; }

.mat-card-subtitle,
.mat-card-content,
.mat-card-header .mat-card-title {
  font-size: 14px; }

.mat-checkbox {
  font-family: SciFly, sans-serif;; }

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px; }

.mat-chip {
  font-size: 13px;
  line-height: 18px; }
  .mat-chip .mat-chip-trailing-icon.mat-icon,
  .mat-chip .mat-chip-remove.mat-icon {
    font-size: 18px; }

.mat-table {
  font-family: SciFly, sans-serif;; }

.mat-header-cell {
  font-size: 12px;
  font-weight: 500; }

.mat-cell, .mat-footer-cell {
  font-size: 14px; }

.mat-calendar {
  font-family: SciFly, sans-serif;; }

.mat-calendar-body {
  font-size: 13px; }

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500; }

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400; }

.mat-dialog-title {
  font: 500 20px/32px SciFly, sans-serif;; }

.mat-expansion-panel-header {
  font-family: SciFly, sans-serif;;
  font-size: 15px;
  font-weight: 400; }

.mat-expansion-panel-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: SciFly, sans-serif;; }

.mat-form-field-wrapper {
  padding-bottom: 1.34375em; }

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125; }

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em; }
  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125; }

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent; }

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em; }

.mat-form-field-label {
  top: 1.34375em; }

.mat-form-field-underline {
  bottom: 1.34375em; }

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.66666667em;
  top: calc(100% - 1.79166667em); }

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0; }

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.33335333%; }

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em; }

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em; }

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.54166667em;
  top: calc(100% - 1.66666667em); }

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0; }

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em; }

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0; }

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em; }

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.33333333%; }

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.33334333%; }

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px; }
  .mat-grid-tile-header .mat-line,
  .mat-grid-tile-footer .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-grid-tile-header .mat-line:nth-child(n+2),
    .mat-grid-tile-footer .mat-line:nth-child(n+2) {
      font-size: 12px; }

input.mat-input-element {
  margin-top: -0.0625em; }

.mat-menu-item {
  font-family: SciFly, sans-serif;;
  font-size: 16px;
  font-weight: 400; }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: SciFly, sans-serif;;
  font-size: 12px; }

.mat-radio-button {
  font-family: SciFly, sans-serif;; }

.mat-select {
  font-family: SciFly, sans-serif;; }

.mat-select-trigger {
  height: 1.125em; }

.mat-slide-toggle-content {
  font: 400 14px/20px SciFly, sans-serif;; }

.mat-slider-thumb-label-text {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: SciFly, sans-serif;; }

.mat-step-label {
  font-size: 14px;
  font-weight: 400; }

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500; }

.mat-tab-group {
  font-family: SciFly, sans-serif;; }

.mat-tab-label, .mat-tab-link {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px SciFly, sans-serif;;
  margin: 0; }

.mat-tooltip {
  font-family: SciFly, sans-serif;;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px; }

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px; }

.mat-list-item {
  font-family: SciFly, sans-serif;; }

.mat-list-option {
  font-family: SciFly, sans-serif;; }

.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  font-size: 16px; }
  .mat-list .mat-list-item .mat-line, .mat-nav-list .mat-list-item .mat-line, .mat-selection-list .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 16px; }
  .mat-list .mat-list-option .mat-line, .mat-nav-list .mat-list-option .mat-line, .mat-selection-list .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 14px; }

.mat-list .mat-subheader, .mat-nav-list .mat-subheader, .mat-selection-list .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 14px;
  font-weight: 500; }

.mat-list[dense] .mat-list-item, .mat-nav-list[dense] .mat-list-item, .mat-selection-list[dense] .mat-list-item {
  font-size: 12px; }
  .mat-list[dense] .mat-list-item .mat-line, .mat-nav-list[dense] .mat-list-item .mat-line, .mat-selection-list[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-list-option, .mat-nav-list[dense] .mat-list-option, .mat-selection-list[dense] .mat-list-option {
  font-size: 12px; }
  .mat-list[dense] .mat-list-option .mat-line, .mat-nav-list[dense] .mat-list-option .mat-line, .mat-selection-list[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box; }
    .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
      font-size: 12px; }

.mat-list[dense] .mat-subheader, .mat-nav-list[dense] .mat-subheader, .mat-selection-list[dense] .mat-subheader {
  font-family: SciFly, sans-serif;;
  font-size: 12px;
  font-weight: 500; }

.mat-option {
  font-family: SciFly, sans-serif;;
  font-size: 16px; }

.mat-optgroup-label {
  font: 500 14px/24px SciFly, sans-serif;; }

.mat-simple-snackbar {
  font-family: SciFly, sans-serif;;
  font-size: 14px; }

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500; }

.mat-tree {
  font-family: SciFly, sans-serif;; }

.mat-tree-node {
  font-weight: 400;
  font-size: 14px; }

.mat-ripple {
  overflow: hidden; }
  @media screen and (-ms-high-contrast: active) {
    .mat-ripple {
      display: none; } }

.mat-ripple.mat-ripple-unbounded {
  overflow: visible; }

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0); }

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cdk-overlay-container {
  position: fixed;
  z-index: 1000; }
  .cdk-overlay-container:empty {
    display: none; }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000; }

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%; }

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0; }
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1; }
    @media screen and (-ms-high-contrast: active) {
      .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: 0.6; } }

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288); }

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0; }

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px; }

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {}@keyframes cdk-text-field-autofill-end {}.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation-name: cdk-text-field-autofill-start; }

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation-name: cdk-text-field-autofill-end; }

textarea.cdk-textarea-autosize {
  resize: none; }

textarea.cdk-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  padding: 2px 0 !important;
  box-sizing: content-box !important; }
